/*!
 * Bootstrap Grid v5.2.3 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #cf6025;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 207, 96, 37;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #cf6025;
  --bs-link-hover-color: #a64d1e;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
}

.bootstrap5 .container,
.bootstrap5 .container-fluid,
.bootstrap5 .container-xxl,
.bootstrap5 .container-xl,
.bootstrap5 .container-lg,
.bootstrap5 .container-md,
.bootstrap5 .container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .bootstrap5 .container-sm, .bootstrap5 .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .bootstrap5 .container-md, .bootstrap5 .container-sm, .bootstrap5 .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .bootstrap5 .container-lg, .bootstrap5 .container-md, .bootstrap5 .container-sm, .bootstrap5 .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .bootstrap5 .container-xl, .bootstrap5 .container-lg, .bootstrap5 .container-md, .bootstrap5 .container-sm, .bootstrap5 .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .bootstrap5 .container-xxl, .bootstrap5 .container-xl, .bootstrap5 .container-lg, .bootstrap5 .container-md, .bootstrap5 .container-sm, .bootstrap5 .container {
    max-width: 1320px;
  }
}
.bootstrap5 .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.bootstrap5 .row > * {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.bootstrap5 .col {
  flex: 1 0 0%;
}
.bootstrap5 .row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.bootstrap5 .row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.bootstrap5 .row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.bootstrap5 .row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.bootstrap5 .row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.bootstrap5 .row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.bootstrap5 .row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
.bootstrap5 .col-auto {
  flex: 0 0 auto;
  width: auto;
}
.bootstrap5 .col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.bootstrap5 .col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.bootstrap5 .col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.bootstrap5 .col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.bootstrap5 .col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.bootstrap5 .col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.bootstrap5 .col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}
.bootstrap5 .col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.bootstrap5 .col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.bootstrap5 .col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.bootstrap5 .col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.bootstrap5 .col-12 {
  flex: 0 0 auto;
  width: 100%;
}
.bootstrap5 .offset-1 {
  margin-left: 8.33333333%;
}
.bootstrap5 .offset-2 {
  margin-left: 16.66666667%;
}
.bootstrap5 .offset-3 {
  margin-left: 25%;
}
.bootstrap5 .offset-4 {
  margin-left: 33.33333333%;
}
.bootstrap5 .offset-5 {
  margin-left: 41.66666667%;
}
.bootstrap5 .offset-6 {
  margin-left: 50%;
}
.bootstrap5 .offset-7 {
  margin-left: 58.33333333%;
}
.bootstrap5 .offset-8 {
  margin-left: 66.66666667%;
}
.bootstrap5 .offset-9 {
  margin-left: 75%;
}
.bootstrap5 .offset-10 {
  margin-left: 83.33333333%;
}
.bootstrap5 .offset-11 {
  margin-left: 91.66666667%;
}
.bootstrap5 .g-0,
.bootstrap5 .gx-0 {
  --bs-gutter-x: 0;
}
.bootstrap5 .g-0,
.bootstrap5 .gy-0 {
  --bs-gutter-y: 0;
}
.bootstrap5 .g-1,
.bootstrap5 .gx-1 {
  --bs-gutter-x: 0.25rem;
}
.bootstrap5 .g-1,
.bootstrap5 .gy-1 {
  --bs-gutter-y: 0.25rem;
}
.bootstrap5 .g-2,
.bootstrap5 .gx-2 {
  --bs-gutter-x: 0.5rem;
}
.bootstrap5 .g-2,
.bootstrap5 .gy-2 {
  --bs-gutter-y: 0.5rem;
}
.bootstrap5 .g-3,
.bootstrap5 .gx-3 {
  --bs-gutter-x: 1rem;
}
.bootstrap5 .g-3,
.bootstrap5 .gy-3 {
  --bs-gutter-y: 1rem;
}
.bootstrap5 .g-4,
.bootstrap5 .gx-4 {
  --bs-gutter-x: 1.5rem;
}
.bootstrap5 .g-4,
.bootstrap5 .gy-4 {
  --bs-gutter-y: 1.5rem;
}
.bootstrap5 .g-5,
.bootstrap5 .gx-5 {
  --bs-gutter-x: 3rem;
}
.bootstrap5 .g-5,
.bootstrap5 .gy-5 {
  --bs-gutter-y: 3rem;
}
@media (min-width: 576px) {
  .bootstrap5 .col-sm {
    flex: 1 0 0%;
  }
  .bootstrap5 .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .bootstrap5 .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap5 .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .bootstrap5 .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .bootstrap5 .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap5 .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap5 .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .bootstrap5 .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .bootstrap5 .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .bootstrap5 .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .bootstrap5 .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .bootstrap5 .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .bootstrap5 .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .offset-sm-0 {
    margin-left: 0;
  }
  .bootstrap5 .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap5 .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap5 .offset-sm-3 {
    margin-left: 25%;
  }
  .bootstrap5 .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap5 .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap5 .offset-sm-6 {
    margin-left: 50%;
  }
  .bootstrap5 .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap5 .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap5 .offset-sm-9 {
    margin-left: 75%;
  }
  .bootstrap5 .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap5 .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap5 .g-sm-0,
.bootstrap5 .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap5 .g-sm-0,
.bootstrap5 .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap5 .g-sm-1,
.bootstrap5 .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap5 .g-sm-1,
.bootstrap5 .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap5 .g-sm-2,
.bootstrap5 .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap5 .g-sm-2,
.bootstrap5 .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap5 .g-sm-3,
.bootstrap5 .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap5 .g-sm-3,
.bootstrap5 .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap5 .g-sm-4,
.bootstrap5 .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap5 .g-sm-4,
.bootstrap5 .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap5 .g-sm-5,
.bootstrap5 .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap5 .g-sm-5,
.bootstrap5 .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .bootstrap5 .col-md {
    flex: 1 0 0%;
  }
  .bootstrap5 .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .bootstrap5 .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap5 .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .bootstrap5 .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .bootstrap5 .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap5 .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap5 .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .bootstrap5 .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .bootstrap5 .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .bootstrap5 .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .bootstrap5 .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .bootstrap5 .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .bootstrap5 .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .offset-md-0 {
    margin-left: 0;
  }
  .bootstrap5 .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap5 .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap5 .offset-md-3 {
    margin-left: 25%;
  }
  .bootstrap5 .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap5 .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap5 .offset-md-6 {
    margin-left: 50%;
  }
  .bootstrap5 .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap5 .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap5 .offset-md-9 {
    margin-left: 75%;
  }
  .bootstrap5 .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap5 .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap5 .g-md-0,
.bootstrap5 .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap5 .g-md-0,
.bootstrap5 .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap5 .g-md-1,
.bootstrap5 .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap5 .g-md-1,
.bootstrap5 .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap5 .g-md-2,
.bootstrap5 .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap5 .g-md-2,
.bootstrap5 .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap5 .g-md-3,
.bootstrap5 .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap5 .g-md-3,
.bootstrap5 .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap5 .g-md-4,
.bootstrap5 .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap5 .g-md-4,
.bootstrap5 .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap5 .g-md-5,
.bootstrap5 .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap5 .g-md-5,
.bootstrap5 .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .bootstrap5 .col-lg {
    flex: 1 0 0%;
  }
  .bootstrap5 .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .bootstrap5 .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap5 .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .bootstrap5 .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .bootstrap5 .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap5 .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap5 .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .bootstrap5 .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .bootstrap5 .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .bootstrap5 .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .bootstrap5 .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .bootstrap5 .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .bootstrap5 .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .offset-lg-0 {
    margin-left: 0;
  }
  .bootstrap5 .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap5 .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap5 .offset-lg-3 {
    margin-left: 25%;
  }
  .bootstrap5 .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap5 .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap5 .offset-lg-6 {
    margin-left: 50%;
  }
  .bootstrap5 .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap5 .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap5 .offset-lg-9 {
    margin-left: 75%;
  }
  .bootstrap5 .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap5 .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap5 .g-lg-0,
.bootstrap5 .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap5 .g-lg-0,
.bootstrap5 .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap5 .g-lg-1,
.bootstrap5 .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap5 .g-lg-1,
.bootstrap5 .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap5 .g-lg-2,
.bootstrap5 .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap5 .g-lg-2,
.bootstrap5 .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap5 .g-lg-3,
.bootstrap5 .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap5 .g-lg-3,
.bootstrap5 .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap5 .g-lg-4,
.bootstrap5 .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap5 .g-lg-4,
.bootstrap5 .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap5 .g-lg-5,
.bootstrap5 .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap5 .g-lg-5,
.bootstrap5 .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .bootstrap5 .col-xl {
    flex: 1 0 0%;
  }
  .bootstrap5 .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .bootstrap5 .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap5 .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .bootstrap5 .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .bootstrap5 .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap5 .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap5 .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .bootstrap5 .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .bootstrap5 .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .bootstrap5 .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .bootstrap5 .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .bootstrap5 .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .bootstrap5 .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .offset-xl-0 {
    margin-left: 0;
  }
  .bootstrap5 .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap5 .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap5 .offset-xl-3 {
    margin-left: 25%;
  }
  .bootstrap5 .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap5 .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap5 .offset-xl-6 {
    margin-left: 50%;
  }
  .bootstrap5 .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap5 .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap5 .offset-xl-9 {
    margin-left: 75%;
  }
  .bootstrap5 .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap5 .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap5 .g-xl-0,
.bootstrap5 .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap5 .g-xl-0,
.bootstrap5 .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap5 .g-xl-1,
.bootstrap5 .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap5 .g-xl-1,
.bootstrap5 .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap5 .g-xl-2,
.bootstrap5 .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap5 .g-xl-2,
.bootstrap5 .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap5 .g-xl-3,
.bootstrap5 .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap5 .g-xl-3,
.bootstrap5 .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap5 .g-xl-4,
.bootstrap5 .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap5 .g-xl-4,
.bootstrap5 .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap5 .g-xl-5,
.bootstrap5 .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap5 .g-xl-5,
.bootstrap5 .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .bootstrap5 .col-xxl {
    flex: 1 0 0%;
  }
  .bootstrap5 .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .bootstrap5 .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .bootstrap5 .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .bootstrap5 .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .bootstrap5 .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .bootstrap5 .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .bootstrap5 .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .bootstrap5 .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .bootstrap5 .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .bootstrap5 .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .bootstrap5 .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .bootstrap5 .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .bootstrap5 .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .bootstrap5 .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .bootstrap5 .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .bootstrap5 .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .bootstrap5 .offset-xxl-0 {
    margin-left: 0;
  }
  .bootstrap5 .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .bootstrap5 .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .bootstrap5 .offset-xxl-3 {
    margin-left: 25%;
  }
  .bootstrap5 .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .bootstrap5 .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .bootstrap5 .offset-xxl-6 {
    margin-left: 50%;
  }
  .bootstrap5 .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .bootstrap5 .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .bootstrap5 .offset-xxl-9 {
    margin-left: 75%;
  }
  .bootstrap5 .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .bootstrap5 .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .bootstrap5 .g-xxl-0,
.bootstrap5 .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .bootstrap5 .g-xxl-0,
.bootstrap5 .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .bootstrap5 .g-xxl-1,
.bootstrap5 .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .bootstrap5 .g-xxl-1,
.bootstrap5 .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .bootstrap5 .g-xxl-2,
.bootstrap5 .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .bootstrap5 .g-xxl-2,
.bootstrap5 .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .bootstrap5 .g-xxl-3,
.bootstrap5 .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .bootstrap5 .g-xxl-3,
.bootstrap5 .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .bootstrap5 .g-xxl-4,
.bootstrap5 .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .bootstrap5 .g-xxl-4,
.bootstrap5 .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .bootstrap5 .g-xxl-5,
.bootstrap5 .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .bootstrap5 .g-xxl-5,
.bootstrap5 .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.bootstrap5 .d-inline {
  display: inline !important;
}
.bootstrap5 .d-inline-block {
  display: inline-block !important;
}
.bootstrap5 .d-block {
  display: block !important;
}
.bootstrap5 .d-grid {
  display: grid !important;
}
.bootstrap5 .d-table {
  display: table !important;
}
.bootstrap5 .d-table-row {
  display: table-row !important;
}
.bootstrap5 .d-table-cell {
  display: table-cell !important;
}
.bootstrap5 .d-flex {
  display: flex !important;
}
.bootstrap5 .d-inline-flex {
  display: inline-flex !important;
}
.bootstrap5 .d-none {
  display: none !important;
}
.bootstrap5 .h-25 {
  height: 25% !important;
}
.bootstrap5 .h-50 {
  height: 50% !important;
}
.bootstrap5 .h-75 {
  height: 75% !important;
}
.bootstrap5 .h-100 {
  height: 100% !important;
}
.bootstrap5 .h-auto {
  height: auto !important;
}
.bootstrap5 .mh-100 {
  max-height: 100% !important;
}
.bootstrap5 .vh-100 {
  height: 100vh !important;
}
.bootstrap5 .min-vh-100 {
  min-height: 100vh !important;
}
.bootstrap5 .flex-fill {
  flex: 1 1 auto !important;
}
.bootstrap5 .flex-row {
  flex-direction: row !important;
}
.bootstrap5 .flex-column {
  flex-direction: column !important;
}
.bootstrap5 .flex-row-reverse {
  flex-direction: row-reverse !important;
}
.bootstrap5 .flex-column-reverse {
  flex-direction: column-reverse !important;
}
.bootstrap5 .flex-grow-0 {
  flex-grow: 0 !important;
}
.bootstrap5 .flex-grow-1 {
  flex-grow: 1 !important;
}
.bootstrap5 .flex-shrink-0 {
  flex-shrink: 0 !important;
}
.bootstrap5 .flex-shrink-1 {
  flex-shrink: 1 !important;
}
.bootstrap5 .flex-wrap {
  flex-wrap: wrap !important;
}
.bootstrap5 .flex-nowrap {
  flex-wrap: nowrap !important;
}
.bootstrap5 .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}
.bootstrap5 .justify-content-start {
  justify-content: flex-start !important;
}
.bootstrap5 .justify-content-end {
  justify-content: flex-end !important;
}
.bootstrap5 .justify-content-center {
  justify-content: center !important;
}
.bootstrap5 .justify-content-between {
  justify-content: space-between !important;
}
.bootstrap5 .justify-content-around {
  justify-content: space-around !important;
}
.bootstrap5 .justify-content-evenly {
  justify-content: space-evenly !important;
}
.bootstrap5 .align-items-start {
  align-items: flex-start !important;
}
.bootstrap5 .align-items-end {
  align-items: flex-end !important;
}
.bootstrap5 .align-items-center {
  align-items: center !important;
}
.bootstrap5 .align-items-baseline {
  align-items: baseline !important;
}
.bootstrap5 .align-items-stretch {
  align-items: stretch !important;
}
.bootstrap5 .align-content-start {
  align-content: flex-start !important;
}
.bootstrap5 .align-content-end {
  align-content: flex-end !important;
}
.bootstrap5 .align-content-center {
  align-content: center !important;
}
.bootstrap5 .align-content-between {
  align-content: space-between !important;
}
.bootstrap5 .align-content-around {
  align-content: space-around !important;
}
.bootstrap5 .align-content-stretch {
  align-content: stretch !important;
}
.bootstrap5 .align-self-auto {
  align-self: auto !important;
}
.bootstrap5 .align-self-start {
  align-self: flex-start !important;
}
.bootstrap5 .align-self-end {
  align-self: flex-end !important;
}
.bootstrap5 .align-self-center {
  align-self: center !important;
}
.bootstrap5 .align-self-baseline {
  align-self: baseline !important;
}
.bootstrap5 .align-self-stretch {
  align-self: stretch !important;
}
.bootstrap5 .order-first {
  order: -1 !important;
}
.bootstrap5 .order-0 {
  order: 0 !important;
}
.bootstrap5 .order-1 {
  order: 1 !important;
}
.bootstrap5 .order-2 {
  order: 2 !important;
}
.bootstrap5 .order-3 {
  order: 3 !important;
}
.bootstrap5 .order-4 {
  order: 4 !important;
}
.bootstrap5 .order-5 {
  order: 5 !important;
}
.bootstrap5 .order-last {
  order: 6 !important;
}
.bootstrap5 .m-0 {
  margin: 0 !important;
}
.bootstrap5 .m-1 {
  margin: 0.25rem !important;
}
.bootstrap5 .m-2 {
  margin: 0.5rem !important;
}
.bootstrap5 .m-3 {
  margin: 1rem !important;
}
.bootstrap5 .m-4 {
  margin: 1.5rem !important;
}
.bootstrap5 .m-5 {
  margin: 3rem !important;
}
.bootstrap5 .m-auto {
  margin: auto !important;
}
.bootstrap5 .mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.bootstrap5 .mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}
.bootstrap5 .mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.bootstrap5 .mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}
.bootstrap5 .mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}
.bootstrap5 .mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}
.bootstrap5 .mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
.bootstrap5 .my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.bootstrap5 .my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.bootstrap5 .my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.bootstrap5 .my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.bootstrap5 .my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.bootstrap5 .my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.bootstrap5 .my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.bootstrap5 .mt-0 {
  margin-top: 0 !important;
}
.bootstrap5 .mt-1 {
  margin-top: 0.25rem !important;
}
.bootstrap5 .mt-2 {
  margin-top: 0.5rem !important;
}
.bootstrap5 .mt-3 {
  margin-top: 1rem !important;
}
.bootstrap5 .mt-4 {
  margin-top: 1.5rem !important;
}
.bootstrap5 .mt-5 {
  margin-top: 3rem !important;
}
.bootstrap5 .mt-auto {
  margin-top: auto !important;
}
.bootstrap5 .me-0 {
  margin-right: 0 !important;
}
.bootstrap5 .me-1 {
  margin-right: 0.25rem !important;
}
.bootstrap5 .me-2 {
  margin-right: 0.5rem !important;
}
.bootstrap5 .me-3 {
  margin-right: 1rem !important;
}
.bootstrap5 .me-4 {
  margin-right: 1.5rem !important;
}
.bootstrap5 .me-5 {
  margin-right: 3rem !important;
}
.bootstrap5 .me-auto {
  margin-right: auto !important;
}
.bootstrap5 .mb-0 {
  margin-bottom: 0 !important;
}
.bootstrap5 .mb-1 {
  margin-bottom: 0.25rem !important;
}
.bootstrap5 .mb-2 {
  margin-bottom: 0.5rem !important;
}
.bootstrap5 .mb-3 {
  margin-bottom: 1rem !important;
}
.bootstrap5 .mb-4 {
  margin-bottom: 1.5rem !important;
}
.bootstrap5 .mb-5 {
  margin-bottom: 3rem !important;
}
.bootstrap5 .mb-auto {
  margin-bottom: auto !important;
}
.bootstrap5 .ms-0 {
  margin-left: 0 !important;
}
.bootstrap5 .ms-1 {
  margin-left: 0.25rem !important;
}
.bootstrap5 .ms-2 {
  margin-left: 0.5rem !important;
}
.bootstrap5 .ms-3 {
  margin-left: 1rem !important;
}
.bootstrap5 .ms-4 {
  margin-left: 1.5rem !important;
}
.bootstrap5 .ms-5 {
  margin-left: 3rem !important;
}
.bootstrap5 .ms-auto {
  margin-left: auto !important;
}
.bootstrap5 .m-n1 {
  margin: -0.25rem !important;
}
.bootstrap5 .m-n2 {
  margin: -0.5rem !important;
}
.bootstrap5 .m-n3 {
  margin: -1rem !important;
}
.bootstrap5 .m-n4 {
  margin: -1.5rem !important;
}
.bootstrap5 .m-n5 {
  margin: -3rem !important;
}
.bootstrap5 .mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}
.bootstrap5 .mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}
.bootstrap5 .mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}
.bootstrap5 .mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}
.bootstrap5 .mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}
.bootstrap5 .my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}
.bootstrap5 .my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}
.bootstrap5 .my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}
.bootstrap5 .my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}
.bootstrap5 .my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}
.bootstrap5 .mt-n1 {
  margin-top: -0.25rem !important;
}
.bootstrap5 .mt-n2 {
  margin-top: -0.5rem !important;
}
.bootstrap5 .mt-n3 {
  margin-top: -1rem !important;
}
.bootstrap5 .mt-n4 {
  margin-top: -1.5rem !important;
}
.bootstrap5 .mt-n5 {
  margin-top: -3rem !important;
}
.bootstrap5 .me-n1 {
  margin-right: -0.25rem !important;
}
.bootstrap5 .me-n2 {
  margin-right: -0.5rem !important;
}
.bootstrap5 .me-n3 {
  margin-right: -1rem !important;
}
.bootstrap5 .me-n4 {
  margin-right: -1.5rem !important;
}
.bootstrap5 .me-n5 {
  margin-right: -3rem !important;
}
.bootstrap5 .mb-n1 {
  margin-bottom: -0.25rem !important;
}
.bootstrap5 .mb-n2 {
  margin-bottom: -0.5rem !important;
}
.bootstrap5 .mb-n3 {
  margin-bottom: -1rem !important;
}
.bootstrap5 .mb-n4 {
  margin-bottom: -1.5rem !important;
}
.bootstrap5 .mb-n5 {
  margin-bottom: -3rem !important;
}
.bootstrap5 .ms-n1 {
  margin-left: -0.25rem !important;
}
.bootstrap5 .ms-n2 {
  margin-left: -0.5rem !important;
}
.bootstrap5 .ms-n3 {
  margin-left: -1rem !important;
}
.bootstrap5 .ms-n4 {
  margin-left: -1.5rem !important;
}
.bootstrap5 .ms-n5 {
  margin-left: -3rem !important;
}
.bootstrap5 .p-0 {
  padding: 0 !important;
}
.bootstrap5 .p-1 {
  padding: 0.25rem !important;
}
.bootstrap5 .p-2 {
  padding: 0.5rem !important;
}
.bootstrap5 .p-3 {
  padding: 1rem !important;
}
.bootstrap5 .p-4 {
  padding: 1.5rem !important;
}
.bootstrap5 .p-5 {
  padding: 3rem !important;
}
.bootstrap5 .px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.bootstrap5 .px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.bootstrap5 .px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.bootstrap5 .px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}
.bootstrap5 .px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.bootstrap5 .px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.bootstrap5 .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.bootstrap5 .py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.bootstrap5 .py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.bootstrap5 .py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.bootstrap5 .py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.bootstrap5 .py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.bootstrap5 .pt-0 {
  padding-top: 0 !important;
}
.bootstrap5 .pt-1 {
  padding-top: 0.25rem !important;
}
.bootstrap5 .pt-2 {
  padding-top: 0.5rem !important;
}
.bootstrap5 .pt-3 {
  padding-top: 1rem !important;
}
.bootstrap5 .pt-4 {
  padding-top: 1.5rem !important;
}
.bootstrap5 .pt-5 {
  padding-top: 3rem !important;
}
.bootstrap5 .pe-0 {
  padding-right: 0 !important;
}
.bootstrap5 .pe-1 {
  padding-right: 0.25rem !important;
}
.bootstrap5 .pe-2 {
  padding-right: 0.5rem !important;
}
.bootstrap5 .pe-3 {
  padding-right: 1rem !important;
}
.bootstrap5 .pe-4 {
  padding-right: 1.5rem !important;
}
.bootstrap5 .pe-5 {
  padding-right: 3rem !important;
}
.bootstrap5 .pb-0 {
  padding-bottom: 0 !important;
}
.bootstrap5 .pb-1 {
  padding-bottom: 0.25rem !important;
}
.bootstrap5 .pb-2 {
  padding-bottom: 0.5rem !important;
}
.bootstrap5 .pb-3 {
  padding-bottom: 1rem !important;
}
.bootstrap5 .pb-4 {
  padding-bottom: 1.5rem !important;
}
.bootstrap5 .pb-5 {
  padding-bottom: 3rem !important;
}
.bootstrap5 .ps-0 {
  padding-left: 0 !important;
}
.bootstrap5 .ps-1 {
  padding-left: 0.25rem !important;
}
.bootstrap5 .ps-2 {
  padding-left: 0.5rem !important;
}
.bootstrap5 .ps-3 {
  padding-left: 1rem !important;
}
.bootstrap5 .ps-4 {
  padding-left: 1.5rem !important;
}
.bootstrap5 .ps-5 {
  padding-left: 3rem !important;
}
@media (min-width: 576px) {
  .bootstrap5 .d-sm-inline {
    display: inline !important;
  }
  .bootstrap5 .d-sm-inline-block {
    display: inline-block !important;
  }
  .bootstrap5 .d-sm-block {
    display: block !important;
  }
  .bootstrap5 .d-sm-grid {
    display: grid !important;
  }
  .bootstrap5 .d-sm-table {
    display: table !important;
  }
  .bootstrap5 .d-sm-table-row {
    display: table-row !important;
  }
  .bootstrap5 .d-sm-table-cell {
    display: table-cell !important;
  }
  .bootstrap5 .d-sm-flex {
    display: flex !important;
  }
  .bootstrap5 .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .bootstrap5 .d-sm-none {
    display: none !important;
  }
  .bootstrap5 .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap5 .flex-sm-row {
    flex-direction: row !important;
  }
  .bootstrap5 .flex-sm-column {
    flex-direction: column !important;
  }
  .bootstrap5 .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap5 .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap5 .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap5 .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap5 .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap5 .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap5 .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap5 .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap5 .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap5 .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .bootstrap5 .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .bootstrap5 .justify-content-sm-center {
    justify-content: center !important;
  }
  .bootstrap5 .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .bootstrap5 .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .bootstrap5 .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .bootstrap5 .align-items-sm-start {
    align-items: flex-start !important;
  }
  .bootstrap5 .align-items-sm-end {
    align-items: flex-end !important;
  }
  .bootstrap5 .align-items-sm-center {
    align-items: center !important;
  }
  .bootstrap5 .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .bootstrap5 .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .bootstrap5 .align-content-sm-start {
    align-content: flex-start !important;
  }
  .bootstrap5 .align-content-sm-end {
    align-content: flex-end !important;
  }
  .bootstrap5 .align-content-sm-center {
    align-content: center !important;
  }
  .bootstrap5 .align-content-sm-between {
    align-content: space-between !important;
  }
  .bootstrap5 .align-content-sm-around {
    align-content: space-around !important;
  }
  .bootstrap5 .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .bootstrap5 .align-self-sm-auto {
    align-self: auto !important;
  }
  .bootstrap5 .align-self-sm-start {
    align-self: flex-start !important;
  }
  .bootstrap5 .align-self-sm-end {
    align-self: flex-end !important;
  }
  .bootstrap5 .align-self-sm-center {
    align-self: center !important;
  }
  .bootstrap5 .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .bootstrap5 .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .bootstrap5 .order-sm-first {
    order: -1 !important;
  }
  .bootstrap5 .order-sm-0 {
    order: 0 !important;
  }
  .bootstrap5 .order-sm-1 {
    order: 1 !important;
  }
  .bootstrap5 .order-sm-2 {
    order: 2 !important;
  }
  .bootstrap5 .order-sm-3 {
    order: 3 !important;
  }
  .bootstrap5 .order-sm-4 {
    order: 4 !important;
  }
  .bootstrap5 .order-sm-5 {
    order: 5 !important;
  }
  .bootstrap5 .order-sm-last {
    order: 6 !important;
  }
  .bootstrap5 .m-sm-0 {
    margin: 0 !important;
  }
  .bootstrap5 .m-sm-1 {
    margin: 0.25rem !important;
  }
  .bootstrap5 .m-sm-2 {
    margin: 0.5rem !important;
  }
  .bootstrap5 .m-sm-3 {
    margin: 1rem !important;
  }
  .bootstrap5 .m-sm-4 {
    margin: 1.5rem !important;
  }
  .bootstrap5 .m-sm-5 {
    margin: 3rem !important;
  }
  .bootstrap5 .m-sm-auto {
    margin: auto !important;
  }
  .bootstrap5 .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap5 .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap5 .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap5 .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap5 .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap5 .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap5 .mt-sm-0 {
    margin-top: 0 !important;
  }
  .bootstrap5 .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap5 .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap5 .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .bootstrap5 .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap5 .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .bootstrap5 .mt-sm-auto {
    margin-top: auto !important;
  }
  .bootstrap5 .me-sm-0 {
    margin-right: 0 !important;
  }
  .bootstrap5 .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap5 .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap5 .me-sm-3 {
    margin-right: 1rem !important;
  }
  .bootstrap5 .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap5 .me-sm-5 {
    margin-right: 3rem !important;
  }
  .bootstrap5 .me-sm-auto {
    margin-right: auto !important;
  }
  .bootstrap5 .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap5 .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .bootstrap5 .ms-sm-0 {
    margin-left: 0 !important;
  }
  .bootstrap5 .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .bootstrap5 .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .bootstrap5 .ms-sm-auto {
    margin-left: auto !important;
  }
  .bootstrap5 .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap5 .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap5 .m-sm-n3 {
    margin: -1rem !important;
  }
  .bootstrap5 .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap5 .m-sm-n5 {
    margin: -3rem !important;
  }
  .bootstrap5 .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .bootstrap5 .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .bootstrap5 .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap5 .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap5 .mt-sm-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap5 .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap5 .mt-sm-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap5 .me-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap5 .me-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap5 .me-sm-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap5 .me-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap5 .me-sm-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap5 .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .ms-sm-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap5 .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .ms-sm-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap5 .p-sm-0 {
    padding: 0 !important;
  }
  .bootstrap5 .p-sm-1 {
    padding: 0.25rem !important;
  }
  .bootstrap5 .p-sm-2 {
    padding: 0.5rem !important;
  }
  .bootstrap5 .p-sm-3 {
    padding: 1rem !important;
  }
  .bootstrap5 .p-sm-4 {
    padding: 1.5rem !important;
  }
  .bootstrap5 .p-sm-5 {
    padding: 3rem !important;
  }
  .bootstrap5 .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap5 .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap5 .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap5 .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap5 .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .pt-sm-0 {
    padding-top: 0 !important;
  }
  .bootstrap5 .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap5 .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap5 .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .bootstrap5 .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap5 .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .bootstrap5 .pe-sm-0 {
    padding-right: 0 !important;
  }
  .bootstrap5 .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap5 .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap5 .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .bootstrap5 .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap5 .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .bootstrap5 .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap5 .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .ps-sm-0 {
    padding-left: 0 !important;
  }
  .bootstrap5 .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .bootstrap5 .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .ps-sm-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .bootstrap5 .d-md-inline {
    display: inline !important;
  }
  .bootstrap5 .d-md-inline-block {
    display: inline-block !important;
  }
  .bootstrap5 .d-md-block {
    display: block !important;
  }
  .bootstrap5 .d-md-grid {
    display: grid !important;
  }
  .bootstrap5 .d-md-table {
    display: table !important;
  }
  .bootstrap5 .d-md-table-row {
    display: table-row !important;
  }
  .bootstrap5 .d-md-table-cell {
    display: table-cell !important;
  }
  .bootstrap5 .d-md-flex {
    display: flex !important;
  }
  .bootstrap5 .d-md-inline-flex {
    display: inline-flex !important;
  }
  .bootstrap5 .d-md-none {
    display: none !important;
  }
  .bootstrap5 .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap5 .flex-md-row {
    flex-direction: row !important;
  }
  .bootstrap5 .flex-md-column {
    flex-direction: column !important;
  }
  .bootstrap5 .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap5 .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap5 .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap5 .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap5 .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap5 .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap5 .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap5 .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap5 .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap5 .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .bootstrap5 .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .bootstrap5 .justify-content-md-center {
    justify-content: center !important;
  }
  .bootstrap5 .justify-content-md-between {
    justify-content: space-between !important;
  }
  .bootstrap5 .justify-content-md-around {
    justify-content: space-around !important;
  }
  .bootstrap5 .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .bootstrap5 .align-items-md-start {
    align-items: flex-start !important;
  }
  .bootstrap5 .align-items-md-end {
    align-items: flex-end !important;
  }
  .bootstrap5 .align-items-md-center {
    align-items: center !important;
  }
  .bootstrap5 .align-items-md-baseline {
    align-items: baseline !important;
  }
  .bootstrap5 .align-items-md-stretch {
    align-items: stretch !important;
  }
  .bootstrap5 .align-content-md-start {
    align-content: flex-start !important;
  }
  .bootstrap5 .align-content-md-end {
    align-content: flex-end !important;
  }
  .bootstrap5 .align-content-md-center {
    align-content: center !important;
  }
  .bootstrap5 .align-content-md-between {
    align-content: space-between !important;
  }
  .bootstrap5 .align-content-md-around {
    align-content: space-around !important;
  }
  .bootstrap5 .align-content-md-stretch {
    align-content: stretch !important;
  }
  .bootstrap5 .align-self-md-auto {
    align-self: auto !important;
  }
  .bootstrap5 .align-self-md-start {
    align-self: flex-start !important;
  }
  .bootstrap5 .align-self-md-end {
    align-self: flex-end !important;
  }
  .bootstrap5 .align-self-md-center {
    align-self: center !important;
  }
  .bootstrap5 .align-self-md-baseline {
    align-self: baseline !important;
  }
  .bootstrap5 .align-self-md-stretch {
    align-self: stretch !important;
  }
  .bootstrap5 .order-md-first {
    order: -1 !important;
  }
  .bootstrap5 .order-md-0 {
    order: 0 !important;
  }
  .bootstrap5 .order-md-1 {
    order: 1 !important;
  }
  .bootstrap5 .order-md-2 {
    order: 2 !important;
  }
  .bootstrap5 .order-md-3 {
    order: 3 !important;
  }
  .bootstrap5 .order-md-4 {
    order: 4 !important;
  }
  .bootstrap5 .order-md-5 {
    order: 5 !important;
  }
  .bootstrap5 .order-md-last {
    order: 6 !important;
  }
  .bootstrap5 .m-md-0 {
    margin: 0 !important;
  }
  .bootstrap5 .m-md-1 {
    margin: 0.25rem !important;
  }
  .bootstrap5 .m-md-2 {
    margin: 0.5rem !important;
  }
  .bootstrap5 .m-md-3 {
    margin: 1rem !important;
  }
  .bootstrap5 .m-md-4 {
    margin: 1.5rem !important;
  }
  .bootstrap5 .m-md-5 {
    margin: 3rem !important;
  }
  .bootstrap5 .m-md-auto {
    margin: auto !important;
  }
  .bootstrap5 .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap5 .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap5 .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap5 .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap5 .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap5 .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap5 .mt-md-0 {
    margin-top: 0 !important;
  }
  .bootstrap5 .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap5 .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap5 .mt-md-3 {
    margin-top: 1rem !important;
  }
  .bootstrap5 .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap5 .mt-md-5 {
    margin-top: 3rem !important;
  }
  .bootstrap5 .mt-md-auto {
    margin-top: auto !important;
  }
  .bootstrap5 .me-md-0 {
    margin-right: 0 !important;
  }
  .bootstrap5 .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap5 .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap5 .me-md-3 {
    margin-right: 1rem !important;
  }
  .bootstrap5 .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap5 .me-md-5 {
    margin-right: 3rem !important;
  }
  .bootstrap5 .me-md-auto {
    margin-right: auto !important;
  }
  .bootstrap5 .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap5 .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .mb-md-auto {
    margin-bottom: auto !important;
  }
  .bootstrap5 .ms-md-0 {
    margin-left: 0 !important;
  }
  .bootstrap5 .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .ms-md-3 {
    margin-left: 1rem !important;
  }
  .bootstrap5 .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .ms-md-5 {
    margin-left: 3rem !important;
  }
  .bootstrap5 .ms-md-auto {
    margin-left: auto !important;
  }
  .bootstrap5 .m-md-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap5 .m-md-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap5 .m-md-n3 {
    margin: -1rem !important;
  }
  .bootstrap5 .m-md-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap5 .m-md-n5 {
    margin: -3rem !important;
  }
  .bootstrap5 .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .bootstrap5 .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .bootstrap5 .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .mt-md-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap5 .mt-md-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap5 .mt-md-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap5 .mt-md-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap5 .mt-md-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap5 .me-md-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap5 .me-md-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap5 .me-md-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap5 .me-md-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap5 .me-md-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap5 .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .mb-md-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .mb-md-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .ms-md-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .ms-md-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .ms-md-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap5 .ms-md-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .ms-md-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap5 .p-md-0 {
    padding: 0 !important;
  }
  .bootstrap5 .p-md-1 {
    padding: 0.25rem !important;
  }
  .bootstrap5 .p-md-2 {
    padding: 0.5rem !important;
  }
  .bootstrap5 .p-md-3 {
    padding: 1rem !important;
  }
  .bootstrap5 .p-md-4 {
    padding: 1.5rem !important;
  }
  .bootstrap5 .p-md-5 {
    padding: 3rem !important;
  }
  .bootstrap5 .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap5 .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap5 .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap5 .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap5 .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .pt-md-0 {
    padding-top: 0 !important;
  }
  .bootstrap5 .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap5 .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap5 .pt-md-3 {
    padding-top: 1rem !important;
  }
  .bootstrap5 .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap5 .pt-md-5 {
    padding-top: 3rem !important;
  }
  .bootstrap5 .pe-md-0 {
    padding-right: 0 !important;
  }
  .bootstrap5 .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap5 .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap5 .pe-md-3 {
    padding-right: 1rem !important;
  }
  .bootstrap5 .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap5 .pe-md-5 {
    padding-right: 3rem !important;
  }
  .bootstrap5 .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap5 .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .ps-md-0 {
    padding-left: 0 !important;
  }
  .bootstrap5 .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .ps-md-3 {
    padding-left: 1rem !important;
  }
  .bootstrap5 .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .ps-md-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 992px) {
  .bootstrap5 .d-lg-inline {
    display: inline !important;
  }
  .bootstrap5 .d-lg-inline-block {
    display: inline-block !important;
  }
  .bootstrap5 .d-lg-block {
    display: block !important;
  }
  .bootstrap5 .d-lg-grid {
    display: grid !important;
  }
  .bootstrap5 .d-lg-table {
    display: table !important;
  }
  .bootstrap5 .d-lg-table-row {
    display: table-row !important;
  }
  .bootstrap5 .d-lg-table-cell {
    display: table-cell !important;
  }
  .bootstrap5 .d-lg-flex {
    display: flex !important;
  }
  .bootstrap5 .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .bootstrap5 .d-lg-none {
    display: none !important;
  }
  .bootstrap5 .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap5 .flex-lg-row {
    flex-direction: row !important;
  }
  .bootstrap5 .flex-lg-column {
    flex-direction: column !important;
  }
  .bootstrap5 .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap5 .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap5 .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap5 .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap5 .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap5 .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap5 .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap5 .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap5 .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap5 .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .bootstrap5 .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .bootstrap5 .justify-content-lg-center {
    justify-content: center !important;
  }
  .bootstrap5 .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .bootstrap5 .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .bootstrap5 .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .bootstrap5 .align-items-lg-start {
    align-items: flex-start !important;
  }
  .bootstrap5 .align-items-lg-end {
    align-items: flex-end !important;
  }
  .bootstrap5 .align-items-lg-center {
    align-items: center !important;
  }
  .bootstrap5 .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .bootstrap5 .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .bootstrap5 .align-content-lg-start {
    align-content: flex-start !important;
  }
  .bootstrap5 .align-content-lg-end {
    align-content: flex-end !important;
  }
  .bootstrap5 .align-content-lg-center {
    align-content: center !important;
  }
  .bootstrap5 .align-content-lg-between {
    align-content: space-between !important;
  }
  .bootstrap5 .align-content-lg-around {
    align-content: space-around !important;
  }
  .bootstrap5 .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .bootstrap5 .align-self-lg-auto {
    align-self: auto !important;
  }
  .bootstrap5 .align-self-lg-start {
    align-self: flex-start !important;
  }
  .bootstrap5 .align-self-lg-end {
    align-self: flex-end !important;
  }
  .bootstrap5 .align-self-lg-center {
    align-self: center !important;
  }
  .bootstrap5 .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .bootstrap5 .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .bootstrap5 .order-lg-first {
    order: -1 !important;
  }
  .bootstrap5 .order-lg-0 {
    order: 0 !important;
  }
  .bootstrap5 .order-lg-1 {
    order: 1 !important;
  }
  .bootstrap5 .order-lg-2 {
    order: 2 !important;
  }
  .bootstrap5 .order-lg-3 {
    order: 3 !important;
  }
  .bootstrap5 .order-lg-4 {
    order: 4 !important;
  }
  .bootstrap5 .order-lg-5 {
    order: 5 !important;
  }
  .bootstrap5 .order-lg-last {
    order: 6 !important;
  }
  .bootstrap5 .m-lg-0 {
    margin: 0 !important;
  }
  .bootstrap5 .m-lg-1 {
    margin: 0.25rem !important;
  }
  .bootstrap5 .m-lg-2 {
    margin: 0.5rem !important;
  }
  .bootstrap5 .m-lg-3 {
    margin: 1rem !important;
  }
  .bootstrap5 .m-lg-4 {
    margin: 1.5rem !important;
  }
  .bootstrap5 .m-lg-5 {
    margin: 3rem !important;
  }
  .bootstrap5 .m-lg-auto {
    margin: auto !important;
  }
  .bootstrap5 .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap5 .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap5 .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap5 .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap5 .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap5 .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap5 .mt-lg-0 {
    margin-top: 0 !important;
  }
  .bootstrap5 .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap5 .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap5 .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .bootstrap5 .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap5 .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .bootstrap5 .mt-lg-auto {
    margin-top: auto !important;
  }
  .bootstrap5 .me-lg-0 {
    margin-right: 0 !important;
  }
  .bootstrap5 .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap5 .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap5 .me-lg-3 {
    margin-right: 1rem !important;
  }
  .bootstrap5 .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap5 .me-lg-5 {
    margin-right: 3rem !important;
  }
  .bootstrap5 .me-lg-auto {
    margin-right: auto !important;
  }
  .bootstrap5 .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap5 .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .bootstrap5 .ms-lg-0 {
    margin-left: 0 !important;
  }
  .bootstrap5 .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .bootstrap5 .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .bootstrap5 .ms-lg-auto {
    margin-left: auto !important;
  }
  .bootstrap5 .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap5 .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap5 .m-lg-n3 {
    margin: -1rem !important;
  }
  .bootstrap5 .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap5 .m-lg-n5 {
    margin: -3rem !important;
  }
  .bootstrap5 .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .bootstrap5 .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .bootstrap5 .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap5 .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap5 .mt-lg-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap5 .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap5 .mt-lg-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap5 .me-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap5 .me-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap5 .me-lg-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap5 .me-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap5 .me-lg-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap5 .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .ms-lg-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap5 .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .ms-lg-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap5 .p-lg-0 {
    padding: 0 !important;
  }
  .bootstrap5 .p-lg-1 {
    padding: 0.25rem !important;
  }
  .bootstrap5 .p-lg-2 {
    padding: 0.5rem !important;
  }
  .bootstrap5 .p-lg-3 {
    padding: 1rem !important;
  }
  .bootstrap5 .p-lg-4 {
    padding: 1.5rem !important;
  }
  .bootstrap5 .p-lg-5 {
    padding: 3rem !important;
  }
  .bootstrap5 .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap5 .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap5 .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap5 .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap5 .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .pt-lg-0 {
    padding-top: 0 !important;
  }
  .bootstrap5 .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap5 .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap5 .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .bootstrap5 .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap5 .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .bootstrap5 .pe-lg-0 {
    padding-right: 0 !important;
  }
  .bootstrap5 .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap5 .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap5 .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .bootstrap5 .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap5 .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .bootstrap5 .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap5 .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .ps-lg-0 {
    padding-left: 0 !important;
  }
  .bootstrap5 .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .bootstrap5 .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .ps-lg-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1200px) {
  .bootstrap5 .d-xl-inline {
    display: inline !important;
  }
  .bootstrap5 .d-xl-inline-block {
    display: inline-block !important;
  }
  .bootstrap5 .d-xl-block {
    display: block !important;
  }
  .bootstrap5 .d-xl-grid {
    display: grid !important;
  }
  .bootstrap5 .d-xl-table {
    display: table !important;
  }
  .bootstrap5 .d-xl-table-row {
    display: table-row !important;
  }
  .bootstrap5 .d-xl-table-cell {
    display: table-cell !important;
  }
  .bootstrap5 .d-xl-flex {
    display: flex !important;
  }
  .bootstrap5 .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .bootstrap5 .d-xl-none {
    display: none !important;
  }
  .bootstrap5 .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap5 .flex-xl-row {
    flex-direction: row !important;
  }
  .bootstrap5 .flex-xl-column {
    flex-direction: column !important;
  }
  .bootstrap5 .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap5 .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap5 .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap5 .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap5 .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap5 .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap5 .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap5 .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap5 .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap5 .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .bootstrap5 .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .bootstrap5 .justify-content-xl-center {
    justify-content: center !important;
  }
  .bootstrap5 .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .bootstrap5 .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .bootstrap5 .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .bootstrap5 .align-items-xl-start {
    align-items: flex-start !important;
  }
  .bootstrap5 .align-items-xl-end {
    align-items: flex-end !important;
  }
  .bootstrap5 .align-items-xl-center {
    align-items: center !important;
  }
  .bootstrap5 .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .bootstrap5 .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .bootstrap5 .align-content-xl-start {
    align-content: flex-start !important;
  }
  .bootstrap5 .align-content-xl-end {
    align-content: flex-end !important;
  }
  .bootstrap5 .align-content-xl-center {
    align-content: center !important;
  }
  .bootstrap5 .align-content-xl-between {
    align-content: space-between !important;
  }
  .bootstrap5 .align-content-xl-around {
    align-content: space-around !important;
  }
  .bootstrap5 .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .bootstrap5 .align-self-xl-auto {
    align-self: auto !important;
  }
  .bootstrap5 .align-self-xl-start {
    align-self: flex-start !important;
  }
  .bootstrap5 .align-self-xl-end {
    align-self: flex-end !important;
  }
  .bootstrap5 .align-self-xl-center {
    align-self: center !important;
  }
  .bootstrap5 .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .bootstrap5 .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .bootstrap5 .order-xl-first {
    order: -1 !important;
  }
  .bootstrap5 .order-xl-0 {
    order: 0 !important;
  }
  .bootstrap5 .order-xl-1 {
    order: 1 !important;
  }
  .bootstrap5 .order-xl-2 {
    order: 2 !important;
  }
  .bootstrap5 .order-xl-3 {
    order: 3 !important;
  }
  .bootstrap5 .order-xl-4 {
    order: 4 !important;
  }
  .bootstrap5 .order-xl-5 {
    order: 5 !important;
  }
  .bootstrap5 .order-xl-last {
    order: 6 !important;
  }
  .bootstrap5 .m-xl-0 {
    margin: 0 !important;
  }
  .bootstrap5 .m-xl-1 {
    margin: 0.25rem !important;
  }
  .bootstrap5 .m-xl-2 {
    margin: 0.5rem !important;
  }
  .bootstrap5 .m-xl-3 {
    margin: 1rem !important;
  }
  .bootstrap5 .m-xl-4 {
    margin: 1.5rem !important;
  }
  .bootstrap5 .m-xl-5 {
    margin: 3rem !important;
  }
  .bootstrap5 .m-xl-auto {
    margin: auto !important;
  }
  .bootstrap5 .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap5 .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap5 .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap5 .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap5 .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap5 .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap5 .mt-xl-0 {
    margin-top: 0 !important;
  }
  .bootstrap5 .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap5 .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap5 .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .bootstrap5 .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap5 .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .bootstrap5 .mt-xl-auto {
    margin-top: auto !important;
  }
  .bootstrap5 .me-xl-0 {
    margin-right: 0 !important;
  }
  .bootstrap5 .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap5 .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap5 .me-xl-3 {
    margin-right: 1rem !important;
  }
  .bootstrap5 .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap5 .me-xl-5 {
    margin-right: 3rem !important;
  }
  .bootstrap5 .me-xl-auto {
    margin-right: auto !important;
  }
  .bootstrap5 .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap5 .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .bootstrap5 .ms-xl-0 {
    margin-left: 0 !important;
  }
  .bootstrap5 .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .bootstrap5 .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .bootstrap5 .ms-xl-auto {
    margin-left: auto !important;
  }
  .bootstrap5 .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap5 .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap5 .m-xl-n3 {
    margin: -1rem !important;
  }
  .bootstrap5 .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap5 .m-xl-n5 {
    margin: -3rem !important;
  }
  .bootstrap5 .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .bootstrap5 .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .bootstrap5 .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap5 .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap5 .mt-xl-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap5 .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap5 .mt-xl-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap5 .me-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap5 .me-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap5 .me-xl-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap5 .me-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap5 .me-xl-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap5 .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .ms-xl-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap5 .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .ms-xl-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap5 .p-xl-0 {
    padding: 0 !important;
  }
  .bootstrap5 .p-xl-1 {
    padding: 0.25rem !important;
  }
  .bootstrap5 .p-xl-2 {
    padding: 0.5rem !important;
  }
  .bootstrap5 .p-xl-3 {
    padding: 1rem !important;
  }
  .bootstrap5 .p-xl-4 {
    padding: 1.5rem !important;
  }
  .bootstrap5 .p-xl-5 {
    padding: 3rem !important;
  }
  .bootstrap5 .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap5 .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap5 .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap5 .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap5 .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .pt-xl-0 {
    padding-top: 0 !important;
  }
  .bootstrap5 .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap5 .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap5 .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .bootstrap5 .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap5 .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .bootstrap5 .pe-xl-0 {
    padding-right: 0 !important;
  }
  .bootstrap5 .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap5 .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap5 .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .bootstrap5 .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap5 .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .bootstrap5 .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap5 .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .ps-xl-0 {
    padding-left: 0 !important;
  }
  .bootstrap5 .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .bootstrap5 .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .ps-xl-5 {
    padding-left: 3rem !important;
  }
}
@media (min-width: 1400px) {
  .bootstrap5 .d-xxl-inline {
    display: inline !important;
  }
  .bootstrap5 .d-xxl-inline-block {
    display: inline-block !important;
  }
  .bootstrap5 .d-xxl-block {
    display: block !important;
  }
  .bootstrap5 .d-xxl-grid {
    display: grid !important;
  }
  .bootstrap5 .d-xxl-table {
    display: table !important;
  }
  .bootstrap5 .d-xxl-table-row {
    display: table-row !important;
  }
  .bootstrap5 .d-xxl-table-cell {
    display: table-cell !important;
  }
  .bootstrap5 .d-xxl-flex {
    display: flex !important;
  }
  .bootstrap5 .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .bootstrap5 .d-xxl-none {
    display: none !important;
  }
  .bootstrap5 .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .bootstrap5 .flex-xxl-row {
    flex-direction: row !important;
  }
  .bootstrap5 .flex-xxl-column {
    flex-direction: column !important;
  }
  .bootstrap5 .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .bootstrap5 .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .bootstrap5 .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .bootstrap5 .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .bootstrap5 .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .bootstrap5 .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .bootstrap5 .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .bootstrap5 .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .bootstrap5 .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .bootstrap5 .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .bootstrap5 .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .bootstrap5 .justify-content-xxl-center {
    justify-content: center !important;
  }
  .bootstrap5 .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .bootstrap5 .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .bootstrap5 .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .bootstrap5 .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .bootstrap5 .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .bootstrap5 .align-items-xxl-center {
    align-items: center !important;
  }
  .bootstrap5 .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .bootstrap5 .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .bootstrap5 .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .bootstrap5 .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .bootstrap5 .align-content-xxl-center {
    align-content: center !important;
  }
  .bootstrap5 .align-content-xxl-between {
    align-content: space-between !important;
  }
  .bootstrap5 .align-content-xxl-around {
    align-content: space-around !important;
  }
  .bootstrap5 .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .bootstrap5 .align-self-xxl-auto {
    align-self: auto !important;
  }
  .bootstrap5 .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .bootstrap5 .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .bootstrap5 .align-self-xxl-center {
    align-self: center !important;
  }
  .bootstrap5 .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .bootstrap5 .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .bootstrap5 .order-xxl-first {
    order: -1 !important;
  }
  .bootstrap5 .order-xxl-0 {
    order: 0 !important;
  }
  .bootstrap5 .order-xxl-1 {
    order: 1 !important;
  }
  .bootstrap5 .order-xxl-2 {
    order: 2 !important;
  }
  .bootstrap5 .order-xxl-3 {
    order: 3 !important;
  }
  .bootstrap5 .order-xxl-4 {
    order: 4 !important;
  }
  .bootstrap5 .order-xxl-5 {
    order: 5 !important;
  }
  .bootstrap5 .order-xxl-last {
    order: 6 !important;
  }
  .bootstrap5 .m-xxl-0 {
    margin: 0 !important;
  }
  .bootstrap5 .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .bootstrap5 .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .bootstrap5 .m-xxl-3 {
    margin: 1rem !important;
  }
  .bootstrap5 .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .bootstrap5 .m-xxl-5 {
    margin: 3rem !important;
  }
  .bootstrap5 .m-xxl-auto {
    margin: auto !important;
  }
  .bootstrap5 .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .bootstrap5 .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .bootstrap5 .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .bootstrap5 .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .bootstrap5 .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .bootstrap5 .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .bootstrap5 .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .bootstrap5 .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .bootstrap5 .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .bootstrap5 .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .bootstrap5 .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .bootstrap5 .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .bootstrap5 .mt-xxl-auto {
    margin-top: auto !important;
  }
  .bootstrap5 .me-xxl-0 {
    margin-right: 0 !important;
  }
  .bootstrap5 .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .bootstrap5 .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .bootstrap5 .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .bootstrap5 .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .bootstrap5 .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .bootstrap5 .me-xxl-auto {
    margin-right: auto !important;
  }
  .bootstrap5 .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .bootstrap5 .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .bootstrap5 .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .bootstrap5 .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .bootstrap5 .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .bootstrap5 .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .bootstrap5 .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .bootstrap5 .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .bootstrap5 .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .bootstrap5 .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .bootstrap5 .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .bootstrap5 .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .bootstrap5 .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .bootstrap5 .ms-xxl-auto {
    margin-left: auto !important;
  }
  .bootstrap5 .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .bootstrap5 .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .bootstrap5 .m-xxl-n3 {
    margin: -1rem !important;
  }
  .bootstrap5 .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .bootstrap5 .m-xxl-n5 {
    margin: -3rem !important;
  }
  .bootstrap5 .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }
  .bootstrap5 .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }
  .bootstrap5 .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .bootstrap5 .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .bootstrap5 .mt-xxl-n3 {
    margin-top: -1rem !important;
  }
  .bootstrap5 .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .bootstrap5 .mt-xxl-n5 {
    margin-top: -3rem !important;
  }
  .bootstrap5 .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .bootstrap5 .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .bootstrap5 .me-xxl-n3 {
    margin-right: -1rem !important;
  }
  .bootstrap5 .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .bootstrap5 .me-xxl-n5 {
    margin-right: -3rem !important;
  }
  .bootstrap5 .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .bootstrap5 .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .bootstrap5 .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .bootstrap5 .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .bootstrap5 .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .bootstrap5 .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .bootstrap5 .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .bootstrap5 .ms-xxl-n3 {
    margin-left: -1rem !important;
  }
  .bootstrap5 .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .bootstrap5 .ms-xxl-n5 {
    margin-left: -3rem !important;
  }
  .bootstrap5 .p-xxl-0 {
    padding: 0 !important;
  }
  .bootstrap5 .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .bootstrap5 .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .bootstrap5 .p-xxl-3 {
    padding: 1rem !important;
  }
  .bootstrap5 .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .bootstrap5 .p-xxl-5 {
    padding: 3rem !important;
  }
  .bootstrap5 .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .bootstrap5 .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .bootstrap5 .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .bootstrap5 .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .bootstrap5 .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .bootstrap5 .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .bootstrap5 .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .bootstrap5 .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .bootstrap5 .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .bootstrap5 .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .bootstrap5 .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .bootstrap5 .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .bootstrap5 .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .bootstrap5 .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .bootstrap5 .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .bootstrap5 .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .bootstrap5 .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .bootstrap5 .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .bootstrap5 .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .bootstrap5 .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .bootstrap5 .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .bootstrap5 .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .bootstrap5 .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .bootstrap5 .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .bootstrap5 .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .bootstrap5 .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .bootstrap5 .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .bootstrap5 .ps-xxl-5 {
    padding-left: 3rem !important;
  }
}
@media print {
  .bootstrap5 .d-print-inline {
    display: inline !important;
  }
  .bootstrap5 .d-print-inline-block {
    display: inline-block !important;
  }
  .bootstrap5 .d-print-block {
    display: block !important;
  }
  .bootstrap5 .d-print-grid {
    display: grid !important;
  }
  .bootstrap5 .d-print-table {
    display: table !important;
  }
  .bootstrap5 .d-print-table-row {
    display: table-row !important;
  }
  .bootstrap5 .d-print-table-cell {
    display: table-cell !important;
  }
  .bootstrap5 .d-print-flex {
    display: flex !important;
  }
  .bootstrap5 .d-print-inline-flex {
    display: inline-flex !important;
  }
  .bootstrap5 .d-print-none {
    display: none !important;
  }
}

/*# sourceMappingURL=bootstrap-grid.css.map */